import React, { useState, useEffect, useLayoutEffect } from "react";
import { useLocation } from "react-router-dom";
import {
  FormHelperText,
  FormControl,
  TextField,
  CircularProgress,
} from "@mui/material";
import Button from "@mui/material/Button";
import { useNavigate } from "react-router-dom";
import Paper from "@mui/material/Paper";
import Grid from "@mui/material/Unstable_Grid2";
import MenuItem from "@mui/material/MenuItem";
import NavBar from "./NavBar";

const genders = [
  {
    value: "male",
    label: "Male",
  },
  {
    value: "female",
    label: "Female",
  },
  {
    value: "both",
    label: "Both",
  },
];

export const EditCampaign = () => {
  const location = useLocation();
  const [templates, setTemplates] = useState(
    eval(location.state.campaign.templates)
  );
  const [keywords, setKeywords] = useState(location.state.campaign.keywords ? location.state.campaign.keywords.join(",") : '');
  const [negative_keywords, setNegativeKeywords] = useState(location.state.campaign.negative_keywords ? location.state.campaign.negative_keywords.join(",") : '');
  const [gender, setGender] = useState(location.state.campaign.gender);
  const [minLimit, setMinLimit] = useState(location.state.campaign.min_limit);
  const [maxLimit, setMaxLimit] = useState(location.state.campaign.max_limit);
  const [minLimitError, setMinLimitError] = React.useState(false);
  const [maxLimitError, setMaxLimitError] = React.useState(false);
  const [messageError, setMessageError] = React.useState(false);
  const [updated, setUpdated] = React.useState(false);
  const [updateError, setUpdateError] = React.useState(false);
  const [deleteError, setDeleteError] = React.useState(false);
  const [deleting, setDeleting] = React.useState(false);
  const [keywordError, setKeywordError] = React.useState(false);
  const [negativeKeywordError, setNegativeKeywordError] = React.useState(false);
  const navigate = useNavigate();
  console.log(`Keyword String: ${location.state.campaign.keywords}`)
  console.log(`${location.state.campaign.negative_keywords}`)
  const campaign_id = location.state.campaign.campaign_id;
  const container_id = location.state.campaign.container_id;
  //escape each message in templates
  //const keywordString = location.state.campaign.keywords ? location.state.campaign.keywords.join(",") : '';
  //const negativeKeywordString = location.state.campaign.negative_keywords ? location.state.campaign.negative_keywords.join(",") : '';

  const handleTemplateChange = (index) => (event) => {
    const values = [...templates];
    values[index] = event.target.value;
    setTemplates(values);
  };

  const handleAddMessage = () => {
    const values = [...templates];
    values.push("");
    setTemplates(values);
  };

  const handleDeleteMessage = () => {
    const values = [...templates];
    values.pop();
    setTemplates(values);
  };

  function handleSubmit(event) {
    event.preventDefault();
    fetch(`${process.env.REACT_APP_SERVER}/editcampaign`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({ campaign_id, templates, keywords, negative_keywords, gender, minLimit, maxLimit }),
    })
      .then((response) => response.json())
      .then((data) => {
        setMessageError(data === "No Personalization");
        setUpdated(data === "Campaign Updated");
        setUpdateError(data === "Error with updating campaign record");
        setKeywordError(data === "Remove extra comma from keywords");
        setNegativeKeywordError(data === "Remove extra comma from negative keywords");
        setMinLimitError(data === "Error with Min Follower Limit");
        setMaxLimitError(data === "Error with Max Follower Limit");
      })
      .catch((error) => console.error(error));
  }

  const handleDelete = (event) => {
    event.preventDefault();
    setDeleting(true);
    fetch(`${process.env.REACT_APP_SERVER}/deletecampaign`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({ campaign_id, container_id }),
    })
      .then((response) => response.json())
      .then((data) => {
        setDeleteError(data === "Error with deleting campaign record");
        if (data === "Error with deleting campaign record") {
          setDeleteError(true);
          setDeleting(false);
        }
        if (data === "Campaign Deleted") {
          console.log("Campaign Deleted");
          navigate("/my-campaigns");
        }
      })
      .catch((error) => console.error(error));
  };

  return (
    <NavBar title="Edit Campaign">
      <Paper elevation={3} style={{ margin: "20px" }}>
        <form onSubmit={handleSubmit}>
          <FormControl fullWidth style={{ padding: "20px" }}>
            <TextField
              style={{ paddingTop: 16, paddingBottom: 16 }}
              readOnly
              InputProps={{ className: "Mui-disabled" }}
              type="text"
              label="Sending messages from this account"
              value={`@${location.state.campaign.twitter_username}`}
            />
            <TextField
              style={{ paddingTop: 16, paddingBottom: 16 }}
              readOnly
              InputProps={{ className: "Mui-disabled" }}
              type="text"
              label="Targeting users who follow"
              value={`@${location.state.campaign.target}`}
            />
            <TextField
              style={{ paddingTop: 16, paddingBottom: 16 }}
              type="text"
              label="What Bio Keywords Are You Looking For? (Seperate with commas)"
              value={keywords}
              onChange={(event) => setKeywords(event.target.value)}
              error={keywordError}
              helperText={keywordError ? "Remove extra comma" : ""}
            />
            <TextField
              style={{ paddingTop: 16, paddingBottom: 16 }}
              type="text"
              label="What Bio Keywords Are You NOT Looking For? (Seperate with commas)"
              value={negative_keywords}
              onChange={(event) => setNegativeKeywords(event.target.value)}
              error={negativeKeywordError}
              helperText={negativeKeywordError ? "Remove extra comma" : ""}
            />
            <TextField
              style={{ paddingTop: 16, paddingBottom: 16 }}
              type="text"
              label="What's the minimum follower limit for a target?"
              value={minLimit}
              onChange={(event) => setMinLimit(event.target.value)}
              error={minLimitError}
              helperText={minLimitError ? "Input Error" : ""}
            />
            <TextField
              style={{ paddingTop: 16, paddingBottom: 16 }}
              type="text"
              label="What's the maximum follower limit for a target?"
              value={maxLimit}
              onChange={(event) => setMaxLimit(event.target.value)}
              error={maxLimitError}
              helperText={maxLimitError ? "Input Error" : ""}
            />
            <TextField
              style={{ paddingTop: 16, paddingBottom: 16 }}
              select
              label="Which Genders Do You Want To Target?"
              defaultValue={location.state.campaign.gender}
              onChange={(event) => setGender(event.target.value)}
            >
              {genders.map((option) => (
                <MenuItem key={option.value} value={option.value}>
                  {option.label}
                </MenuItem>
              ))}
            </TextField>
            <div>
              {templates.map((template, index) => (
                <TextField
                  required
                  key={index}
                  style={{ paddingTop: 16, paddingBottom: 16 }}
                  id="outlined-multiline-static"
                  label={`What message do you want to send?`}
                  value={template}
                  onChange={handleTemplateChange(index)}
                  multiline
                  fullWidth
                  error={messageError}
                  helperText={
                    messageError
                      ? "Add '{}' to your message to personlize with the user's firstname"
                      : ""
                  }
                />
              ))}
              <Button onClick={handleAddMessage}>Add Message</Button>
              {templates.length > 1 && (
                <Button color="secondary" onClick={handleDeleteMessage}>
                  Delete Message
                </Button>
              )}
            </div>
            <Button type="submit" variant="contained">
              Update Campaign
            </Button>
            <Button
              variant="outlined"
              onClick={(event) => handleDelete(event)}
              color="secondary"
            >
              Delete Campaign
            </Button>
            {updated && (
              <FormHelperText>Successfully Updated Campaign!</FormHelperText>
            )}
            {updateError && (
              <FormHelperText>Error Updating Campaign</FormHelperText>
            )}
            {deleteError && (
              <FormHelperText>Error Deleting Campaign</FormHelperText>
            )}
            {deleting && (
              <CircularProgress
                style={{ margin: "20px", alignSelf: "center" }}
              />
            )}
          </FormControl>
        </form>
      </Paper>
    </NavBar>
  );
};
