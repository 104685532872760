import Grid from "@mui/material/Unstable_Grid2";
import Divider from "@mui/material/Divider";
import Button from "@mui/material/Button";
import React, { useState, useEffect } from "react";
import { useAuthenticator } from "@aws-amplify/ui-react";
import getStripe from "../lib/getStripe";
import { FormHelperText, Paper, CircularProgress } from "@mui/material";
import NavBar from "./NavBar";
import { useNavigate } from "react-router-dom";



const Subscription = (props) => {
  let [message, setMessage] = useState("");
  let [success, setSuccess] = useState(false);
  let [sessionId, setSessionId] = useState("");
  const [checkoutError, setCheckoutError] = React.useState(false);
  const [manageError, setManageError] = React.useState(false);
  const { user } = useAuthenticator();
  const [subscribed, setSubscribed] = React.useState(false);
  const [subLoading, setSubLoading] = useState(true);
  const [offerTrial, setOfferTrial] = useState(true)

  useEffect(() => {
    fetch(
      `${process.env.REACT_APP_SERVER}/check-subscription?email=` +
        user.attributes.email
    )
      .then((response) => response.json())
      .then((data) => {
        console.log(data["subscribed"])
        if (data["subscribed"] === "was") {
          setOfferTrial(false)
        } 
        if (data["subscribed"] === "is") {
          setSubscribed(true);

        }
        if (data["subscribed"] === "never") {
          setOfferTrial(true)
        }
        setSubLoading(false);
      });
  }, []);


  useEffect(() => {
    // Check to see if this is a redirect back from Checkout
    const query = new URLSearchParams(window.location.search);

    if (query.get("success")) {
      setSuccess(true);
      setSessionId(query.get("session_id"));
    }

    if (query.get("canceled")) {
      setSuccess(false);
      setMessage(
        "Order canceled -- continue to shop around and checkout when you're ready."
      );
    }
  }, [sessionId]);


  if (subLoading) {
    return null;
  }

  const Message = ({ message }) => (
    <section>
      <p>{message}</p>
    </section>
  );

  function manageBilling(event) {
    event.preventDefault();
    const data = {
      email: user.attributes.email
    };
    fetch(`${process.env.REACT_APP_SERVER}/create-portal-session`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(data),
    })
    .then((response) => response.json())
    .then((data) => {
      if (data === "Server error") {
        setManageError(true);
      }
      else {
        window.location = data;
      }
    })
    .catch((error) => console.error(error));

  }


  function handleSubscribe(event) {
    event.preventDefault();
    const data = {
      lookup_key: "standard",
      email: user.attributes.email
    };
    const url = offerTrial ? `${process.env.REACT_APP_SERVER}/create-trial-checkout-session`: `${process.env.REACT_APP_SERVER}/create-checkout-session`;
    // const url = `${process.env.REACT_APP_SERVER}/create-checkout-session`;
    console.log(url)
    fetch(url, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(data)
    })
    .then((response) => response.json())
    .then((data) => {
      if (data === "Server error") {
        setCheckoutError(true);
      }
      else {
        window.location = data;
      }
    })
    .catch((error) => console.error(error));
  }

  const ProductDisplay = () => (
    <>
      <Paper elevation={3} style={{ margin: "20px" }}>
        <h2 style={{ textAlign: "center" }}>BirdLaunch</h2>
        <p style={{ textAlign: "center" }}>The Rule of 100 Plan</p>
        <Divider />
        <p style={{ textAlign: "center" }}>
          Automate Sending 100 Personalized Cold DMs Per Day
        </p>
        <Divider />
        <h2 style={{ textAlign: "center" }}>$99.00</h2>
        <p style={{ textAlign: "center" }}>/month</p>
      </Paper>
      {subscribed ? 
      (
        <Button
          onClick={(event) => manageBilling(event)}                  
          variant="contained"
          color="secondary"
          style={{ display: "block", margin: "0px 20px 20px 20px" }}
        >
          Manage Billing
        </Button>
      )
      :
      (
        <Button
          onClick={(event) => handleSubscribe(event)}
          variant="contained"
          color="primary"
          style={{ display: "block", flexGrow: "1", margin: "0px 20px 20px 20px"}}
        >
          {offerTrial ? "Try It Free For 72 Hours!" : "Upgrade"}
          {/* {"Subscribe"} */}
        </Button>
      )}
    </>
  );

  const SuccessDisplay = ({ sessionId }) => {
    return (
      <>
        <Paper elevation={3} style={{ margin: "20px" }}>
          <h2 style={{ textAlign: "center" }}>Subscription to The BirdLaunch Plan successful!</h2>
        </Paper>
        <Button
          onClick={(event) => manageBilling(event, sessionId)}
          variant="contained"
          color="secondary"
          style={{ display: "block", flexGrow: "1" }}
        >
          Manage your billing information
        </Button>
      </>);
        {/* <form action="/create-portal-session" method="POST">
          <input
            type="hidden"
            id="session-id"
            name="session_id"
            value={sessionId}
          />
          <button id="checkout-and-portal-button" type="submit">
            Manage your billing information
          </button>
        </form> */}
  };


  if (!success && message === "") {
    return (<NavBar title="Subscription">
        <ProductDisplay />
        {checkoutError && (<FormHelperText>Error Creating Checkout Session</FormHelperText>)}
      </NavBar>);
  } else if (success && sessionId !== "") {
    return (
      <NavBar title="Subscription">
        <Paper elevation={3} style={{ margin: "20px" }}>
          <SuccessDisplay sessionId={sessionId} />
          {manageError && (<FormHelperText>Error Creating Session To Manage Billing</FormHelperText>)}
        </Paper>
      </NavBar>
    );
  } else {
    return (
      <NavBar title="Subscription">
        <Paper elevation={3} style={{ margin: "20px" }}>
          <Message message={message} />
        </Paper>
      </NavBar>
    );
  }
};

export default Subscription;
