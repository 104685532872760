import "@aws-amplify/ui-react/styles.css";
import {
  BrowserRouter as Router,
  Routes,
  Route,
  Redirect
} from "react-router-dom";
import React, { useState, useEffect, useParams } from "react";
import { useLocation, useNavigate } from 'react-router-dom';
import Home from "./Components/Home";
import NewCampaign from "./Components/NewCampaign";
import MyCampaigns from "./Components/MyCampaigns";
import { EditCampaign } from "./Components/EditCampaign";
import TwitterAuth from "./Components/TwitterAuth";
import { Authenticator } from "@aws-amplify/ui-react";
import Subscription from "./Components/Subscription";
import { RequireAuth } from "./lib/RequireAuth";
import { Login } from "./Components/Login";
import './App.css';
import TermsOfUse from "./Components/TermsOfUse";
import PrivacyPolicy from "./Components/PrivacyPolicy";
import FAQ from "./Components/FAQ";
import DailyTargets from "./Components/DailyTargets";
import CRM from "./Components/CRM";

//const URL = "server.birdlaunch.io";
// const URL = "127.0.0.1:5000";

function App() {
  return (
    <Authenticator.Provider>
      <Router>
        <Routes>
          <Route exact path="/" element={(<Home />)}></Route>
          <Route path="/new-campaign" element={(<RequireAuth><NewCampaign /></RequireAuth>)}></Route>
          <Route path="/my-campaigns" element={(<RequireAuth><MyCampaigns /></RequireAuth>)}></Route>
          <Route path="/edit-campaign" element={(<RequireAuth><EditCampaign /></RequireAuth>)}></Route>
          <Route path="/auth" element={(<RequireAuth><TwitterAuth /></RequireAuth>)}></Route>
          <Route path="/subscription" element={(<RequireAuth><Subscription /></RequireAuth>)}></Route>
          <Route path="/login" element={<Login />} />
          {/* <Route path='/crm' element={<RequireAuth><CRM/></RequireAuth>}/> */}
          <Route path='/terms' element={<TermsOfUse/>}/>
          <Route path='/privacy' element={<PrivacyPolicy/>}/>
          <Route path='/faq' element={<RequireAuth><FAQ/></RequireAuth>}/>
        </Routes>
      </Router>
    </Authenticator.Provider>
  );
}

export default App;
