import Grid from "@mui/material/Unstable_Grid2";
import Paper from "@mui/material/Paper";
import Button from "@mui/material/Button";
import NavBar from "./NavBar";
import { useAuthenticator } from "@aws-amplify/ui-react";
import SalesPage from "./SalesPage";
import React, { Fragment } from "react";
import { Link, useNavigate } from "react-router-dom";

const Home = () => {
  const { route } = useAuthenticator((context) => [context.route]);
  const navigate = useNavigate();

  return route === "authenticated" ? (
    <NavBar title="Home">
      <Paper elevation={3} style={{ margin: "20px", padding: "30px" }}>
        <div>
          <h4 style={{ marginTop: "10px", marginBottom: "10px" }}>
            What is BirdLaunch?
          </h4>
          <p style={{ marginTop: "0px" }}>
            BirdLaunch is a software tool that will automate your outreach
            towards your ideal clients on Twitter.
          </p>
        </div>
      </Paper>
      <Paper elevation={3} style={{ margin: "20px", padding: "30px" }}>
        <div>
          <h4 style={{ marginTop: "10px", marginBottom: "10px" }}>
            How To Get Started
          </h4>
          <ul>
            <li>Start a <Link to={'/subscription'}>Free Trial</Link> or <Link to={'/subscription'}>Subscription</Link></li>
            <li><Link to={'/auth'}>Authenticate</Link> your Twitter account</li>
            <li>Ensure that you've verified your phone number in your Twitter acccount settings</li>
            <li><Link to={'/new-campaign'}>Start a campaign</Link></li>
            <li>Monitor your Twitter inbox for replies</li>
          </ul>
          <h4 style={{ marginTop: "10px", marginBottom: "10px" }}>
            Video Walkthrough For Creating A Campaign
          </h4>
          <div
            style={{
              position: "relative",
              paddingBottom: "300px",
              height: "0",
            }}
          >
            <iframe
              title="Walkthrough"
              src="https://www.loom.com/embed/3719fbefc1e9447793c39eac641dca73"
              frameBorder="0"
              allowFullScreen
              style={{
                position: "absolute",
                top: "0",
                left: "0",
                maxWidth: "640px",
                maxHeight: "300px",
                width: "100%",
                height: "100%",
              }}
            ></iframe>
          </div>
        </div>
      </Paper>
    </NavBar>
  ) : (
    <SalesPage />
  );
};

//backgroundColor: "#f2f2f2"
export default Home;
