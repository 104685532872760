import React, { useState, useEffect } from "react";
import TextField from "@mui/material/TextField";
import { useNavigate } from "react-router-dom";
import Button from "@mui/material/Button";
import { FormHelperText, FormControl, Box, Container } from "@mui/material";
import MenuItem from "@mui/material/MenuItem";
import { useAuthenticator } from "@aws-amplify/ui-react";
import Grid from "@mui/material/Unstable_Grid2";
import Paper from "@mui/material/Paper";
import NavBar from "./NavBar";

const options = [
  {
    value: "male",
    label: "Male",
  },
  {
    value: "female",
    label: "Female",
  },
  {
    value: "both",
    label: "Both",
  },
];

const NewCampaign = () => {
  const [target, setTarget] = useState("");
  const [keywords, setKeywords] = useState("");
  const [negativeKeywords, setNegativeKeywords] = useState("");
  const [messages, setMessages] = useState([{ value: "" }]);
  const [gender, setGender] = useState("both");
  const [minLimit, setMinLimit] = useState("")
  const [maxLimit, setMaxLimit] = useState("")
  const [targetError, setTargetError] = React.useState(false);
  const [messageError, setMessageError] = React.useState(false);
  const [keywordError, setKeywordError] = React.useState(false);
  const [minLimitError, setMinLimitError] = React.useState(false);
  const [maxLimitError, setMaxLimitError] = React.useState(false);
  const [negativeKeywordError, setNegativeKeywordError] = React.useState(false);
  const [limitError, setLimitError] = React.useState(false);
  const [authError, setAuthError] = React.useState(false);
  const [subLoading, setSubLoading] = useState(true);
  //const [isStarted, setIsStarted] = React.useState(false);
  const navigate = useNavigate();
  const { user } = useAuthenticator();

  useEffect(() => {
    fetch(
      `${process.env.REACT_APP_SERVER}/check-subscription?email=` +
        user.attributes.email
    )
      .then((respone) => respone.json())
      .then((data) => {
        if (data["subscribed"] !== "is") {
          setSubLoading(false);
          navigate("/subscription");
        } else {
          setSubLoading(false);
        }
      });
  }, []);

  if (subLoading) {
    return null;
  }

  const handleMessageChange = (index) => (event) => {
    const values = [...messages];
    values[index].value = event.target.value;
    setMessages(values);
  };

  const handleAddMessage = () => {
    const values = [...messages];
    values.push({ value: "" });
    setMessages(values);
  };

  const handleDeleteMessage = () => {
    const values = [...messages];
    values.pop();
    setMessages(values);
  };

  function handleSubmit(event) {
    event.preventDefault();
    const data = {
      target: target,
      keywords: keywords,
      negative_keywords: negativeKeywords,
      messages: messages,
      gender: gender,
      user_id: user.attributes.sub,
      min_limit: minLimit,
      max_limit: maxLimit
    };
    fetch(`${process.env.REACT_APP_SERVER}/startcampaign`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(data),
    })
      .then((response) => response.json())
      .then((responseData) => {
        // Handle the response data
        console.log(responseData);
        setTargetError(responseData === "Invalid Target Username");
        setMessageError(responseData === "One or more messages missing personalization");
        setKeywordError(responseData === "Remove extra comma from keywords");
        setNegativeKeywordError(responseData === "Remove extra comma from negative keywords");
        setAuthError(responseData === "Not authenticated");
        setLimitError(responseData === "Campaign Record Already Exists");
        setMinLimitError(responseData === "Error with Min Follower Limit");
        setMaxLimitError(responseData === "Error with Max Follower Limit");
        if (responseData === "Campaign Started!") {
          navigate("/my-campaigns");
        }
      });
  }
  return (
    <NavBar title="New Campaign">
      <Paper elevation={3} style={{ margin: "20px" }}>
        <form
          style={{ display: "flex", flexDirection: "column" }}
          onSubmit={handleSubmit}
        >
          <FormControl style={{ padding: "20px" }}>
            {/* <h1>Create a New Campaign</h1> */}
            <TextField
              style={{ paddingTop: 16, paddingBottom: 16 }}
              required
              type="text"
              label="Whose Followers Do You Want To Target?"
              value={target}
              onChange={(event) => setTarget(event.target.value)}
              error={targetError}
              helperText={targetError ? "Invalid Username" : ""}
            />
            <TextField
              style={{ paddingTop: 16, paddingBottom: 16 }}
              type="text"
              label="Which Bio Keywords Are You Looking For? (Seperate with commas)"
              value={keywords}
              onChange={(event) => setKeywords(event.target.value)}
              error={keywordError}
              helperText={keywordError ? "Remove extra comma" : ""}
            />
            <TextField
              style={{ paddingTop: 16, paddingBottom: 16 }}
              type="text"
              label="Which Bio Keywords Do You Want To AVOID? (Seperate with commas)"
              value={negativeKeywords}
              onChange={(event) => setNegativeKeywords(event.target.value)}
              error={negativeKeywordError}
              helperText={negativeKeywordError ? "Remove extra comma" : ""}
            />
            <TextField
              style={{ paddingTop: 16, paddingBottom: 16 }}
              type="text"
              label="What's the minimum follower limit for a target?"
              value={minLimit}
              onChange={(event) => setMinLimit(event.target.value)}
              error={minLimitError}
              helperText={minLimitError ? "Input Error" : ""}
            />
            <TextField
              style={{ paddingTop: 16, paddingBottom: 16 }}
              type="text"
              label="What's the maximum follower limit for a target?"
              value={maxLimit}
              onChange={(event) => setMaxLimit(event.target.value)}
              error={maxLimitError}
              helperText={maxLimitError ? "Input Error" : ""}
            />
            <TextField
              style={{ paddingTop: 16, paddingBottom: 16 }}
              id="outlined-select-gender"
              select
              label="Which Genders Do You Want To Target?"
              defaultValue={"both"}
              onChange={(event) => setGender(event.target.value)}
            >
              {options.map((option) => (
                <MenuItem key={option.value} value={option.value}>
                  {option.label}
                </MenuItem>
              ))}
            </TextField>
            <div>
              {messages.map((message, index) => (
                <TextField
                  required
                  key={index}
                  style={{ paddingTop: 16, paddingBottom: 16 }}
                  id="outlined-multiline-static"
                  label={`What message do you want to send? (Add '{}' to insert user's firstname)`}
                  value={message.value}
                  onChange={handleMessageChange(index)}
                  multiline
                  fullWidth
                  error={messageError}
                  helperText={
                    messageError
                      ? "Add '{}' to your message to personlize with the user's firstname"
                      : ""
                  }
                />
              ))}
              <Button onClick={handleAddMessage}>Add Message</Button>
              {messages.length > 1 && (
                <Button onClick={handleDeleteMessage} color="secondary">
                  Delete Message
                </Button>
              )}
            </div>
            <Button type="submit" variant="contained">
              Create Campaign
            </Button>
            {limitError && (
              <FormHelperText>
                Error: Can't Have More Than One Campaign Running
              </FormHelperText>
            )}
            {authError && (
              <FormHelperText>
                Error: You must Authenticate a Twitter Account
              </FormHelperText>
            )}
          </FormControl>
        </form>
      </Paper>
    </NavBar>
  );
};

export default NewCampaign;
