import React from "react";
import NavBar from "./NavBar";
import Grid from "@mui/material/Unstable_Grid2";
import Paper from "@mui/material/Paper";

const FAQ = () => {
  return (
    <NavBar title="Frequently Asked Questions">
      <Paper elevation={3} style={{ margin: "20px", padding: "30px" }}>
        <div>
          <h4 style={{ marginTop: "10px", marginBottom: "10px" }}>
            Do I need to add an @ before the target username when creating a
            campaign?
          </h4>
          <p style={{ marginTop: "0px" }}>
            No. Don't add an @ before the username because it will cause an
            error with creating a campaign
          </p>
        </div>
      </Paper>
      <Paper elevation={3} style={{ margin: "20px", padding: "30px" }}>
        <div>
          <h4 style={{ marginTop: "10px", marginBottom: "10px" }}>
            Why aren't any messages being sent?
          </h4>
          <p style={{ marginTop: "0px" }}>
            It may be because you haven't verified your phone number with your
            Twitter account. If you have verified your phone number and you
            started your campaign over 24 hours ago, then please contact
            support.
          </p>
        </div>
      </Paper>
      <Paper elevation={3} style={{ margin: "20px", padding: "30px" }}>
        <div>
          <h4 style={{ marginTop: "10px", marginBottom: "10px" }}>
            What does it mean if my account gets locked after starting a campaign?
          </h4>
          <p style={{ marginTop: "0px" }}>
            Twitter locks accounts after your start using a 3rd party app to verify that you are still in control of your account.
          </p>
        </div>
      </Paper>
      <Paper elevation={3} style={{ margin: "20px", padding: "30px" }}>
        <div>
          <h4 style={{ marginTop: "10px", marginBottom: "10px" }}>
            What's the worst thing that can happen to my account from using this software?
          </h4>
          <p style={{ marginTop: "0px" }}>
            The worst thing that can happen to your account is a temporary restriction, but it has never happned to any BirdLaunch users.
          </p>
        </div>
      </Paper>
      <Paper elevation={3} style={{ margin: "20px", padding: "30px" }}>
        <div>
          <h4 style={{ marginTop: "10px", marginBottom: "10px" }}>
            Why does it say my Twitter account is still not authenticated?
          </h4>
          <p style={{ marginTop: "0px" }}>
            If you authenticated your Twitter account, but it isn't reflecting that in the app then it's because you've tried to authenticate an account that has been authenticated by another BirdLaunch account.
          </p>
        </div>
      </Paper>
      <Paper elevation={3} style={{ margin: "20px", padding: "30px" }}>
        <div>
          <h4 style={{ marginTop: "10px", marginBottom: "10px" }}>
            Will any users ever be messaged twice?
          </h4>
          <p style={{ marginTop: "0px" }}>
            No, the software keeps track of every account it has messaged to
            prevent messaging the same user twice from the same account.
          </p>
        </div>
      </Paper>
      <Paper elevation={3} style={{ margin: "20px", padding: "30px"}}>
        <div>
          <h4 style={{ marginTop: "10px", marginBottom: "10px" }}>
            How do I personalize messages?
          </h4>
          <p style={{ marginTop: "0px" }}>
            You personalize messages by adding {"{"}
            {"}"} to the message. This will add the user's first name to your
            message.
          </p>
        </div>
      </Paper>
      <Paper elevation={3} style={{ margin: "20px", padding: "30px" }}>
        <div>
          <h4 style={{ marginTop: "10px", marginBottom: "10px" }}>
            What are the best practices for cold DMs?
          </h4>
          <p style={{ marginTop: "0px" }}>
            Keep them short with the intent to start a conversation, not to sell
            them with the first message.
          </p>
        </div>
      </Paper>
      <Paper elevation={3} style={{ margin: "20px", padding: "30px" }}>
        <div>
          <h4 style={{ marginTop: "10px", marginBottom: "10px" }}>
            How can I contact support?
          </h4>
          <p style={{ marginTop: "0px" }}>
            You can contact support by emailing{" "}
            <a href="mailto:hello@birdlaunch.io">hello@birdlaunch.io</a>
          </p>
        </div>
      </Paper>
    </NavBar>
  );
};

export default FAQ;
