import React, { useState, useEffect, useLayoutEffect } from "react";
import { useAuthenticator } from "@aws-amplify/ui-react";
import Table from "@mui/material/Table";
import { FormHelperText } from "@mui/material";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import { Link, useNavigate } from "react-router-dom";
import Grid from "@mui/material/Unstable_Grid2";
import Button from "@mui/material/Button";
import NavBar from "./NavBar";

const MyCampaigns = () => {
  const navigate = useNavigate();
  const [campaigns, setCampaigns] = useState([]);
  const [subLoading, setSubLoading] = useState(true);
  const [serverError, setServerError] = React.useState(false);
  const { user } = useAuthenticator();
  let queryString =
    `${process.env.REACT_APP_SERVER}/getcampaigns?id=` + user.attributes.sub;

  // get the list of campaigns for the current user
  useEffect(() => {
    fetch(queryString)
      .then((response) => response.json())
      .then((data) => {
        if (data === 'Error with retrieving campaign records'){
          setServerError(true);
        }
        else {
        setCampaigns(data);
        }
      });
  }, []);

  useEffect(() => {
    fetch(
      `${process.env.REACT_APP_SERVER}/check-subscription?email=` +
        user.attributes.email
    )
      .then((respone) => respone.json())
      .then((data) => {
        if (data["subscribed"] !== "is") {
          setSubLoading(false);
          navigate("/subscription");
        } else {
          setSubLoading(false);
        }
      });
  }, []);

  if (subLoading) {
    return null;
  }

  const handleClick = (event, campaign) => {
    navigate("/edit-campaign", {
      state: { campaign },
    });
  };

  return (
    <NavBar title="Manage Campaigns">
      <Paper elevation={3} style={{ margin: "20px" }}>
        {campaigns.length > 0 ? (
          <TableContainer component={Paper}>
            <Table aria-label="simple table">
              <TableHead>
                <TableRow
                  style={{
                    display: "flex",
                    flexFlow: "row wrap",
                    justifyContent: "space-around",
                  }}
                >
                  <TableCell style={{ minWidth: "200px", textAlign: "center" }}>
                    Campaign ID
                  </TableCell>
                  <TableCell style={{ minWidth: "200px", textAlign: "center" }}>
                    Account
                  </TableCell>
                  <TableCell style={{ minWidth: "200px", textAlign: "center" }}>
                    Target
                  </TableCell>
                  <TableCell style={{ minWidth: "200px", textAlign: "center" }}>
                    Status
                  </TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {campaigns.map((campaign) => (
                  <TableRow
                    key={campaign.twitter_username}
                    hover
                    sx={{
                      "&:last-child td, &:last-child th": { border: 0 },
                    }}
                    onClick={(event) => handleClick(event, campaign)}
                    style={{
                      display: "flex",
                      flexFlow: "row wrap",
                      justifyContent: "space-around",
                    }}
                  >
                    <TableCell
                      style={{ minWidth: "200px", textAlign: "center" }}
                    >
                      {campaign.campaign_id}
                    </TableCell>
                    <TableCell
                      style={{ minWidth: "200px", textAlign: "center" }}
                    >{`@${campaign.twitter_username}`}</TableCell>
                    <TableCell
                      style={{ minWidth: "200px", textAlign: "center" }}
                    >{`@${campaign.target}`}</TableCell>
                    <TableCell
                      style={{ minWidth: "200px", textAlign: "center" }}
                    >
                      {campaign.status}
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
        ) : (
          <div style={{ display: "flex", flexDirection: "column" }}>
            <h3 style={{ alignSelf: "flex-start", margin: "20px" }}>
              You have no campaigns!
            </h3>
            <Button
              style={{ display: "block", margin: "20px" }}
              variant="contained"
              onClick={(event) => navigate("/new-campaign")}
              color="primary"
            >
              Create A Campaign
            </Button>
          </div>
        )}
        {serverError && (
          <FormHelperText>
            Error: Can't Have More Than One Campaign Running
          </FormHelperText>
        )}
      </Paper>
    </NavBar>
  );
};

export default MyCampaigns;
