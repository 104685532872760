import React from "react";
import {
  Button,
  Divider,
  Typography,
  Collapse,
  Modal,
  Box,
  Fade,
  Slide,
} from "@mui/material";
import { useNavigate, useLocation, Link } from "react-router-dom";
import "../styles/salesPage.css";
import targetsScreenshot from "../static/daily_targets.png";
import newCampaignScreenshot from "../static/newVersion.png";
import raj from "../static/raj_profile.jpeg";
import david from "../static/david_avatar.png";
import lisa from "../static/lisa_avatar.jpeg";
import VSLthumbnail from "../static/VSL_thumbnail.png";
import Paper from "@mui/material/Paper";
import ForumOutlinedIcon from "@mui/icons-material/ForumOutlined";
import HandshakeOutlinedIcon from "@mui/icons-material/HandshakeOutlined";
import PersonOutlinedIcon from "@mui/icons-material/PersonOutlined";
import PersonSearchOutlinedIcon from "@mui/icons-material/PersonSearchOutlined";
import CheckCircleOutlineOutlinedIcon from "@mui/icons-material/CheckCircleOutlineOutlined";
import AddIcon from "@mui/icons-material/Add";
import PlayCircleIcon from "@mui/icons-material/PlayCircle";
import Carousel from "react-material-ui-carousel";

const SalesPage = () => {
  const [show, setShow] = React.useState(false);
  const [q1, setQ1] = React.useState(false);
  const [q2, setQ2] = React.useState(false);
  const [q3, setQ3] = React.useState(false);
  const navigate = useNavigate();
  const location = useLocation();
  const [checked, setChecked] = React.useState(true);
  const containerRef = React.useRef(null);

  const handleChange = (index) => {
    setChecked((prev) => !prev);
  };

  const videoUrl =
    "https://screenpal.com/player/c0QhVAVCcY7?width=100%&height=100%&ff=1&title=0";

  const handleClick = (event) => {
    navigate("/login", {
      replace: true,
      state: { from: location },
    });
  };

  const handleShow = (event) => {
    setShow(true);
  };

  const handleClose = (event) => {
    setShow(false);
  };

  const handleQ1 = () => {
    setQ1((prev) => !prev);
  };

  const handleQ2 = () => {
    setQ2((prev) => !prev);
  };

  const handleQ3 = () => {
    setQ3((prev) => !prev);
  };

  const goHome = (event) => {
    navigate("/", {
      replace: true,
      state: { from: location },
    });
  };

  return (
    <div>
      <div
        className="one"
        style={{
          display: "flex",
          flexDirection: "row",
          width: "100%",
          justifyContent: "space-between",
          alignItems: "center",
          paddingTop: "30px",
          paddingBottom: "30px",
        }}
      >
        <h3
          onClick={(event) => goHome(event)}
          style={{ color: "white", cursor: "pointer" }}
        >
          BirdLaunch
        </h3>
        <Button
          style={{ maxHeight: "40px", minHeight: "35px" }}
          variant="contained"
          onClick={(event) => handleClick(event)}
        >
          Login
        </Button>
      </div>
      <div className="section one">
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            maxWidth: "1140px",
          }}
        >
          <h1
            style={{
              color: "white",
              maxWidth: "610px",
              marginTop: "0px",
              marginBottom: "30px",
            }}
          >
            Generate high quality leads on autopilot through Twitter
          </h1>
          <div
            style={{ marginBottom: "30px", color: "white", maxWidth: "610px" }}
          >
            BirdLaunch is the solution to never worry about your lead generation
            again by automating your cold DM outreach on Twitter.
          </div>
          <Button
            onClick={(event) => handleClick(event)}
            style={{ marginBottom: "60px" }}
            variant="contained"
          >
            Try It Free For 72 Hours!
          </Button>
          <div
            className="container"
            style={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              padding: "16px",
            }}
          >
            <video
              className="video"
              controls
              poster={VSLthumbnail}
              style={{ width: "100%", height: "auto", maxWidth: "800px" }}
            >
              <source
                src={"https://birdlaunch-dev.s3.amazonaws.com/VSL.mp4"}
                type="video/mp4"
              />
              Your browser does not support the video tag.
            </video>
          </div>
        </div>
      </div>
      <div
        className="section"
        style={{ paddingTop: "60px", paddingBottom: "60px" }}
      >
        <div>
          <h2>What people are saying</h2>
        </div>
        <div
          style={{
            display: "flex",
            flexDirection: "row",
            justifyContent: "center",
            alignItems: "center",
            flexWrap: "wrap",
          }}
        >
          <Testimonial
            avatar={david}
            statement={
              '"I got engagement on day one which concluded with a hugely profitable sale more than covering the cost of this software, which is exactly what you want and need from any software."'
            }
            username={"@TribalIdeas"}
          />
          <Testimonial
            avatar={raj}
            statement={
              '"I had to turn off my campaign within a few days because I had too many leads coming in!"'
            }
            username={"@KingsGameCoach"}
          />
          <Testimonial
            avatar={lisa}
            statement={
              '"I booked a sales call within 24 hours of starting my first campaign."'
            }
            username={"@lisa_frattali"}
          />
        </div>
      </div>
      <div className="section two">
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            alignItems: "flex-start",
            maxWidth: "1140px",
            padding: "0px 5% 5% 5%",
          }}
        >
          <h2 style={{ alignSelf: "center" }}>How it works</h2>
          <div style={{ padding: "15px 15px 15px 0px" }}>
            <img
              src={newCampaignScreenshot}
              alt="Create a campaign"
              style={{
                maxWidth: "100%",
                boxSizing: "border-box",
                boxShadow: "10px 10px 5px #888888",
              }}
            />
          </div>
          <h4 style={{ textAlign: "left" }}>
            Step 1 - Create a campaign to start messaging
          </h4>
          <p style={{ textAlign: "left" }}>
            All you need to do to create a camapign is to pick a Twitter user
            that your ideal customers follow, decide if you'd like to filter
            those followers by keywords in their bio, their follower count, or
            by their gender and then create personalized message templates to
            split test.
          </p>
          <h4 style={{ textAlign: "left" }}>
            Step 2 - Watch the leads come in
          </h4>
          <p style={{ textAlign: "left" }}>
            Your campaign will automatically start messaging 100 users per day
            so you can just sit back and monitor your direct messages for
            replies.
          </p>
        </div>
      </div>
      <div className="section" style={{ backgroundColor: "rgb(21, 20, 20)" }}>
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            maxWidth: "1140px",
            padding: "90px 15px 90px 15px",
          }}
        >
          <h2
            style={{
              color: "white",
              maxWidth: "610px",
              marginTop: "0px",
              marginBottom: "30px",
            }}
          >
            Start generating high quality leads on Twitter today.
          </h2>
          <Button onClick={(event) => handleClick(event)} variant="contained">
            Try It Free For 72 Hours!
          </Button>
        </div>
      </div>
      <div className="section" style={{ padding: "80px 5% 80px 5%" }}>
        <p
          style={{
            color: "#8a94a6",
            fontSize: "14px",
            fontWeight: "700",
            letterSpacing: "1px",
            marginBottom: "4px",
            marginTop: "0px",
          }}
        >
          OUR KEY FEATURES
        </p>
        <h2 style={{ marginTop: "0px", marginBottom: "10px" }}>
          Why BirdLaunch?
        </h2>
        <div
          style={{
            display: "flex",
            flexDirection: "row",
            justifyContent: "center",
            width: "100%",
          }}
        >
          <Divider
            orientation="vertical"
            flexItem
            style={{ minHeight: "55px" }}
          />
        </div>
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            padding: "20px 12px 20px 12px",
            marginBottom: "15px",
          }}
        >
          <Paper
            elevation={2}
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              width: "50px",
              height: "50px",
              borderRadius: "50%",
              marginBottom: "30px",
            }}
          >
            <ForumOutlinedIcon color="primary" />
          </Paper>
          <h4
            style={{ color: "#1976d2", marginBottom: "15px", marginTop: "0px" }}
          >
            Effortless Outreach
          </h4>
          <p style={{ marginBottom: "18px", marginTop: "0px" }}>
            You can effortlessly send 100 personalized cold outreach messages a
            day.
          </p>
        </div>
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            padding: "20px 12px 20px 12px",
            marginBottom: "15px",
          }}
        >
          <Paper
            elevation={2}
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              width: "50px",
              height: "50px",
              borderRadius: "50%",
              marginBottom: "30px",
            }}
          >
            <HandshakeOutlinedIcon color="primary" />
          </Paper>
          <h4
            style={{ color: "#1976d2", marginBottom: "15px", marginTop: "0px" }}
          >
            High Reply Rate
          </h4>
          <p style={{ marginBottom: "18px", marginTop: "0px" }}>
            Cold DMs have a much higher reply rate than cold emails.
          </p>
        </div>
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            padding: "20px 12px 20px 12px",
            marginBottom: "15px",
          }}
        >
          <Paper
            elevation={2}
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              width: "50px",
              height: "50px",
              borderRadius: "50%",
              marginBottom: "30px",
            }}
          >
            <PersonSearchOutlinedIcon color="primary" />
          </Paper>
          <h4
            style={{ color: "#1976d2", marginBottom: "15px", marginTop: "0px" }}
          >
            Low Cost Per Lead
          </h4>
          <p style={{ marginBottom: "18px", marginTop: "0px" }}>
            The cost per lead from streamlining Twitter outreach is just a
            fraction of the cost per lead from paid ads.
          </p>
        </div>
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            padding: "20px 12px 20px 12px",
            marginBottom: "15px",
          }}
        >
          <Paper
            elevation={2}
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              width: "50px",
              height: "50px",
              borderRadius: "50%",
              marginBottom: "30px",
            }}
          >
            <PersonOutlinedIcon color="primary" />
          </Paper>
          <h4
            style={{ color: "#1976d2", marginBottom: "15px", marginTop: "0px" }}
          >
            Target the right audience
          </h4>
          <p style={{ marginBottom: "18px", marginTop: "0px" }}>
            You're able to get specific with your targeting because you can
            target users who follow specific accounts, have specific keywords in
            their bio, and a specific gender.
          </p>
        </div>
      </div>
      <div className="section" style={{ padding: "0px 5% 60px 5%" }}>
        <p
          style={{
            color: "#8a94a6",
            fontSize: "14px",
            fontWeight: "700",
            letterSpacing: "1px",
            marginBottom: "4px",
            marginTop: "0px",
          }}
        >
          PRICING
        </p>
        <h2 style={{ marginTop: "0px", marginBottom: "45px" }}>
          The Only Plan You Need For Client Acquisiton!
        </h2>
        <Paper
          elevation={2}
          style={{
            display: "flex",
            flexDirection: "column",
            alignItems: "flex-start",
            marginBottom: "30px",
            width: "100%",
            padding: "25px 15px 35px 15px",
            borderRadius: "20px",
            maxWidth: "500px",
          }}
        >
          <div
            style={{ display: "flex", flexDirection: "column", width: "100%" }}
          >
            <div>
              <div
                style={{
                  textAlign: "left",
                  minHeight: "30px",
                  fontWeight: "400",
                  letterSpacing: "1px",
                  color: "#c1c7d0",
                }}
              >
                STANDARD
              </div>
            </div>
            <div
              style={{
                display: "flex",
                flexDirection: "row",
                alignItems: "flex-start",
                marginBottom: "25px",
                marginTop: "15px",
                color: "#1976d2",
              }}
            >
              <div>$</div>
              <div style={{ fontSize: "50px", lineHeight: "50px" }}>99</div>
              <div>month</div>
            </div>
          </div>
          <Divider style={{ width: "100%", marginBottom: "35px" }} />
          <div
            style={{
              display: "flex",
              flexDirection: "row",
              marginBottom: "15px",
            }}
          >
            <div style={{ marginRight: "15px" }}>
              <CheckCircleOutlineOutlinedIcon style={{ color: "#43C100" }} />
            </div>
            <div>1 Running Campaign</div>
          </div>
          <div
            style={{
              display: "flex",
              flexDirection: "row",
              marginBottom: "15px",
            }}
          >
            <div style={{ marginRight: "15px" }}>
              <CheckCircleOutlineOutlinedIcon style={{ color: "#43C100" }} />
            </div>
            <div>100 Messages / Day</div>
          </div>
          <div
            style={{
              display: "flex",
              flexDirection: "row",
              marginBottom: "15px",
            }}
          >
            <div style={{ marginRight: "15px" }}>
              <CheckCircleOutlineOutlinedIcon style={{ color: "#43C100" }} />
            </div>
            <div>1 Twitter Account</div>
          </div>
          <Button
            style={{ marginTop: "55px", alignSelf: "center" }}
            variant="outlined"
            onClick={(event) => handleClick(event)}
          >
            Try It Free For 72 Hours!
          </Button>
        </Paper>
        <p style={{ marginTop: "30px", marginBottom: "10px" }}>
          Want a custom plan? Please contact{" "}
          <a href="mailto:hello@birdlaunch.io">support</a>
        </p>
      </div>
      <Divider style={{ width: "100%" }} />
      <div className="section" style={{ padding: "90px 0px 90px 0px" }}>
        <div style={{ maxWidth: "1140px", marginBottom: "35px" }}>
          <p
            style={{
              color: "#8a94a6",
              fontSize: "14px",
              fontWeight: "700",
              letterSpacing: "1px",
              marginBottom: "4px",
              marginTop: "0px",
            }}
          >
            HOW CAN WE HELP?
          </p>
          <h2 style={{ marginTop: "0px", marginBottom: "10px" }}>
            Frequently Asked Questions
          </h2>
        </div>
        <div style={{ width: "100%", maxWidth: "800px" }}>
          <div style={{ width: "100%" }}>
            <div
              onClick={(event) => handleQ1(event)}
              style={{
                display: "flex",
                flexDirection: "row",
                justifyContent: "space-between",
                alignItems: "flex-start",
                width: "100%",
                padding: "10px 30px 10px 30px",
              }}
            >
              <div
                style={{
                  textAlign: "left",
                  paddingRight: "5px",
                  marginBottom: "15px",
                  lineHeight: "1.2",
                  color: "#11164d",
                  fontSize: "17px",
                  fontWeight: "500",
                }}
              >
                Could my account get permanently banned if I use this tool?
              </div>
              <AddIcon style={{ width: "15px", height: "15px" }} />
            </div>
            <Divider style={{ width: "100%" }} />
            <div
              style={{ padding: "15px 30px 15px 30px", paddingBottom: "15px" }}
            >
              <Collapse style={{ textAlign: "left" }} in={q1}>
                No, we've done extensive testing to make sure your account is
                safe. You will not get permanently banned solely from using this
                tool.
              </Collapse>
            </div>
          </div>
          <div style={{ width: "100%" }}>
            <div
              onClick={(event) => handleQ2(event)}
              style={{
                display: "flex",
                flexDirection: "row",
                justifyContent: "space-between",
                alignItems: "flex-start",
                width: "100%",
                padding: "10px 30px 10px 30px",
              }}
            >
              <div
                style={{
                  textAlign: "left",
                  paddingRight: "5px",
                  marginBottom: "15px",
                  lineHeight: "1.2",
                  color: "#11164d",
                  fontSize: "17px",
                  fontWeight: "500",
                }}
              >
                What is your refund policy?
              </div>
              <AddIcon style={{ width: "15px", height: "15px" }} />
            </div>
            <Divider style={{ width: "100%" }} />
            <div
              style={{ padding: "15px 30px 15px 30px", paddingBottom: "15px" }}
            >
              <Collapse style={{ textAlign: "left" }} in={q2}>
                We don't offer refunds. All plans can be cancelled monthly.
              </Collapse>
            </div>
          </div>
          <div style={{ width: "100%" }}>
            <div
              onClick={(event) => handleQ3(event)}
              style={{
                display: "flex",
                flexDirection: "row",
                justifyContent: "space-between",
                alignItems: "flex-start",
                width: "100%",
                padding: "10px 30px 10px 30px",
              }}
            >
              <div
                style={{
                  textAlign: "left",
                  paddingRight: "5px",
                  marginBottom: "15px",
                  lineHeight: "1.2",
                  color: "#11164d",
                  fontSize: "17px",
                  fontWeight: "500",
                }}
              >
                Where can I contact customer service?
              </div>
              <AddIcon style={{ width: "15px", height: "15px" }} />
            </div>
            <Divider style={{ width: "100%" }} />
            <div
              style={{ padding: "15px 30px 15px 30px", paddingBottom: "15px" }}
            >
              <Collapse style={{ textAlign: "left" }} in={q3}>
                You can contact our customer service at hello@birdlaunch.io.
              </Collapse>
            </div>
          </div>
        </div>
      </div>
      <div className="section" style={{ padding: "0px 5% 100px 5%" }}>
        <div style={{ display: "flex", flexDirection: "column" }}>
          <div>
            <h2
              style={{
                padding: "10px 20px",
                marginTop: "0px",
                marginBottom: "0px",
              }}
            >
              BirdLaunch
            </h2>
          </div>
          <div style={{ margin: "25px 0px" }}>
            Feel free to contact us.
            <br />
            <a href="mailto:hello@birdlaunch.io">hello@birdlaunch.io</a>
          </div>
          <Link to="/terms" style={{ textDecoration: "none", color: "black" }}>
            Terms of use
          </Link>
          <Link
            to="/privacy"
            style={{ textDecoration: "none", color: "black" }}
          >
            Privacy Policy
          </Link>
          <div style={{ marginTop: "25px" }}>
            ©2023 BirdLaunch. All rights reserved.
          </div>
        </div>
      </div>
    </div>
  );
};

const Testimonial = ({ avatar, statement, username }) => {
  return (
    <Paper
      elevation={3}
      style={{
        margin: "20px",
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        justifyContent: "space-between",
        width: "80%",
        padding: "10px 25px 10px 25px",
        maxWidth: "400px",
        minHeight: "360px",
      }}
    >
      <div style={{ marginBottom: "15px" }}>
        <img
          src={avatar}
          alt="Raj's Avatar"
          style={{
            width: "65px",
            height: "65px",
            borderWidth: "5px",
            borderStyle: "solid",
            borderColor: "#fff",
            borderRadius: "50%",
            boxShadow: "14px 14px 40px 7px #ebecf0",
          }}
        />
      </div>
      <div
        style={{
          maxWidth: "420px",
          color: "#66748d",
          fontSize: "17px",
          fontWeight: "500",
          textAlign: "center",
        }}
      >
        {statement}
      </div>
      <div
        style={{
          marginTop: "35px",
          color: "#66748d",
          fontSize: "17px",
          fontWeight: "500",
        }}
      >
        {username}
      </div>
    </Paper>
  );
};

export default SalesPage;
