/* eslint-disable */
// WARNING: DO NOT EDIT. This file is automatically generated by AWS Amplify. It will be overwritten.

const awsmobile = {
    "aws_project_region": "us-east-1",
    "aws_cognito_identity_pool_id": "us-east-1:2caae875-f527-4a63-94da-7da3db571bc2",
    "aws_cognito_region": "us-east-1",
    "aws_user_pools_id": "us-east-1_YbnJfea6a",
    "aws_user_pools_web_client_id": "51333rpm7l3esbt0ccpsm8ic6c",
    "oauth": {}
};


export default awsmobile;
