// components/Login.js
import { useEffect } from "react";
import { useNavigate, useLocation, Link } from "react-router-dom";
import { Authenticator, useAuthenticator, View } from "@aws-amplify/ui-react";
import { Button, Divider, Typography } from "@mui/material";
import "@aws-amplify/ui-react/styles.css";

export function Login() {
  const { route } = useAuthenticator((context) => [context.route]);
  const location = useLocation();
  const navigate = useNavigate();
  let from = location.state?.from?.pathname || "/";
  useEffect(() => {
    if (route === "authenticated") {
      navigate(from, { replace: true });
    }
  }, [route, navigate, from]);


  const handleClick = (event) => {
    navigate("/login", {
      replace: true,
      state: { from: location },
    });
  };

  const goHome = (event) => {
    navigate("/", {
      replace: true,
      state: { from: location },
    });
  };


  return (
    <div>
      <div
        style={{
          display: "flex",
          flexDirection: "row",
          width: "100%",
          justifyContent: "space-between",
          alignItems: "center",
          padding: "30px 5%",
        }}
      >
        <h3
          onClick={(event) => goHome(event)}
          style={{ color: "black", cursor: "pointer" }}
        >
          BirdLaunch
        </h3>
        <Button
          style={{ maxHeight: "40px", minHeight: "35px" }}
          variant="outlined"
          onClick={(event) => handleClick(event)}
        >
          Login
        </Button>
      </div>
      <Divider />
      <div className="section" style={{display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center', padding: "100px 5%" }}>
        <h1 style={{marginBottom: '15px'}}>We're Sorry! BirdLaunch is no longer available</h1>
        <h4 style={{color: '#8a94a6', marginTop: '0px'}}>BirdLaunch is no longer available because of updates to the Twitter API.</h4>
        <View className="auth-wrapper">

          {/* <Authenticator loginMechanisms={["email"]} /> */}
        </View>
      </div>
      <div className="section" style={{display: 'flex', flexDirection: 'column', alignItems: 'center', padding: "0px 5% 100px 5%" }}>
        <div style={{ display: "flex", flexDirection: "column" }}>
          <div>
            <h2
              style={{
                padding: "10px 20px",
                marginTop: "0px",
                marginBottom: "0px",
              }}
            >
              BirdLaunch
            </h2>
          </div>
          <div style={{ margin: "25px 0px" }}>
            Feel free to contact us.
            <br />
            <a href="mailto:hello@birdlaunch.io">hello@birdlaunch.io</a>
          </div>
          <Link to="/terms" style={{ textDecoration: "none", color: "black" }}>
            Terms of use
          </Link>
          <Link
            to="/privacy"
            style={{ textDecoration: "none", color: "black" }}
          >
            Privacy Policy
          </Link>
          <div style={{ marginTop: "25px" }}>
            ©2023 BirdLaunch. All rights reserved.
          </div>
        </div>
      </div>
    </div>
  );
}
