import { useAuthenticator } from "@aws-amplify/ui-react";
import React, { useState, useEffect } from "react";
import Button from "@mui/material/Button";
import Grid from "@mui/material/Unstable_Grid2";
import { FormHelperText, Paper, CircularProgress } from "@mui/material";
import { Link, useNavigate } from "react-router-dom";
import NavBar from "./NavBar";

const TwitterAuth = () => {
  const navigate = useNavigate();
  const { user } = useAuthenticator();
  // use this on the premium page
  // Will want to check if they have their token already
  const authenticateTwitter = (user) => {
    let url =
      `${process.env.REACT_APP_SERVER}/twitter/login?id=` + user.attributes.sub;
    window.location = url;
  };

  //check if they have an access token
  const [authenticated, setAuthenticated] = useState(false);
  const [username, setUsername] = useState("");
  const [serial_id, setSerialID] = useState();
  const [authLoading, setAuthLoading] = useState(true);
  const [subLoading, setSubLoading] = useState(true);
  const [deleting, setDeleting] = React.useState(false);
  const [deleteError, setDeleteError] = React.useState(false);

  let queryString =
    `${process.env.REACT_APP_SERVER}/authenticated?id=` + user.attributes.sub;

  useEffect(() => {
    fetch(queryString)
      .then((response) => response.json())
      .then((data) => {
        setAuthenticated(data["authenticated"]);
        setUsername(data["username"]);
        setAuthLoading(false);
        setSerialID(data["serial_id"]);
      });
  }, []);

  useEffect(() => {
    fetch(
      `${process.env.REACT_APP_SERVER}/check-subscription?email=` +
        user.attributes.email
    )
      .then((respone) => respone.json())
      .then((data) => {
        if (data["subscribed"] !== "is") {
          navigate("/subscription");
        } else {
          setSubLoading(false);
        }
      });
  }, []);

  const handleDelete = (event) => {
    event.preventDefault();
    setDeleting(true);
    fetch(`${process.env.REACT_APP_SERVER}/delete-auth`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({ serial_id }),
    })
      .then((response) => response.json())
      .then((data) => {
        console.log(data);
        if (data === "Error deleting authentication record") {
          setDeleteError(true);
          setDeleting(false);
        }
        if (data === "Authentication Deleted") {
          console.log("Authentication Deleted");
          setDeleting(false);
          window.location.reload();
        }
      })
      .catch((error) => console.error(error));
  };

  if (authLoading || subLoading) {
    return null;
  }

  return (
    <NavBar title="Authentication">
      <Paper elevation={3} style={{ margin: "20px" }}>
        {authenticated ? (
          <div style={{ display: "flex", flexDirection: "column" }}>
            <h3
              style={{ alignSelf: "flex-start", margin: "20px" }}
            >{`You've already authenticated @${username}`}</h3>
            <Button
              style={{ display: "block", margin: "20px 20px 20px 20px" }}
              variant="contained"
              onClick={(event) => navigate("/new-campaign")}
              color="primary"
            >
              Create A Campaign
            </Button>
            {/* <Button
              style={{
                display: "block",
                margin: "10px 20px 20px 20px",
              }}
              variant="contained"
              onClick={(event) => handleDelete(event)}
              color="secondary"
            >
              Remove Authenticated Account
            </Button> */}
            {deleteError && (
              <FormHelperText>Error Deleting Authentication</FormHelperText>
            )}
            {deleting && (
              <CircularProgress
                style={{ margin: "20px", alignSelf: "center" }}
              />
            )}
          </div>
        ) : (
          <div style={{ display: "flex", flexDirection: "column" }}>
            <h3 style={{ alignSelf: "flex-start", margin: "20px" }}>
              Click below to authenticated a twitter account
            </h3>
            <h4 style={{ alignSelf: "flex-start", margin: "20px" }}>
              Be sure to verify your phone number for your Twitter account so
              that your messages will be delivered!
            </h4>
            <Button
              style={{ display: "block", margin: "20px" }}
              variant="contained"
              onClick={() => authenticateTwitter(user)}
            >
              Authenticate Twitter
            </Button>
          </div>
        )}
      </Paper>
    </NavBar>
  );
};

export default TwitterAuth;
